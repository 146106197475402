import { FC, useEffect } from "react";
import { Grid, Link, Typography } from "@mui/material";
import { useFormContext, useWatch } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import { CheckboxInput, SwitchInput } from "@dzambalaorg/mui-inputs-core";

import { StyledFormGroup, StyledTypographyGroup } from "./styled";

export const Erc1155: FC = () => {
  const form = useFormContext<any>();
  const moduleNative = useWatch({ name: "module.native" });
  const moduleErc20 = useWatch({ name: "module.erc20" });
  const moduleErc1155 = useWatch({ name: "module.erc1155" });

  useEffect(() => {
    if (!(moduleErc20 || moduleNative)) {
      form.setValue("module.erc1155", false);
      form.setValue("erc1155.contract", false);
      form.setValue("erc1155.template", false);
      return;
    }

    form.setValue("erc1155.contract", moduleErc1155);
    form.setValue("erc1155.template", moduleErc1155);
  }, [moduleErc1155]);

  return (
    <Grid>
      <SwitchInput name="module.erc1155" />
      <StyledTypographyGroup>
        <FormattedMessage id="pages.calculator.erc1155.requirements" />
      </StyledTypographyGroup>
      <StyledFormGroup>
        <CheckboxInput name="erc1155.contract" disabled />
        <Typography>
          <FormattedMessage id="pages.calculator.erc1155.contract" />
          <Link href="https://dzambalaorg.github.io/admin/hierarchy/ERC1155/contract/">more</Link>
        </Typography>
        <CheckboxInput name="erc1155.template" disabled />
        <Typography>
          <FormattedMessage id="pages.calculator.erc1155.template" />
          <Link href="https://dzambalaorg.github.io/admin/hierarchy/ERC1155/template/">more</Link>
        </Typography>
      </StyledFormGroup>
    </Grid>
  );
};
