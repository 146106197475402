import type { RouteObject } from "react-router-dom";

import { Pricing } from "./main";
import { CalculatorSaas } from "./saas";
import { CalculatorSelfHosted } from "./self-hosted";

export const pricingRoutes: Array<RouteObject> = [
  {
    path: "/pricing",
    children: [
      { index: true, element: <Pricing /> },
      { path: "/pricing/saas", element: <CalculatorSaas /> },
      { path: "/pricing/self-hosted", element: <CalculatorSelfHosted /> },
    ],
  },
];
