import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Typography, Grid } from "@mui/material";

import { Root } from "../../../../components/common/styled";

const tech = [
  {
    url: "alchemy.svg",
    title: "Alchemy",
    enabled: false,
  },
  {
    url: "allure.svg",
    title: "Allure",
    enabled: true,
  },
  {
    url: "amocrm.svg",
    title: "amoCRM",
    enabled: false,
  },
  {
    url: "aws.svg",
    title: "AWS",
    enabled: true,
  },
  {
    url: "bash.svg",
    title: "bash",
    enabled: true,
  },
  {
    url: "besu.svg",
    title: "BESU",
    enabled: true,
  },
  {
    url: "binance.svg",
    title: "BINANCE",
    enabled: true,
  },
  {
    url: "chai.svg",
    title: "chai",
    enabled: true,
  },
  {
    url: "chain-link.svg",
    title: "ChainLink",
    enabled: true,
  },
  {
    url: "coin-gecko.svg",
    title: "CoinGecko",
    enabled: true,
  },
  {
    url: "css3.svg",
    title: "CSS3",
    enabled: false,
  },
  {
    url: "data-grip.svg",
    title: "DataGrip",
    enabled: true,
  },
  {
    url: "docker.svg",
    title: "Docker",
    enabled: true,
  },
  {
    url: "enjin.svg",
    title: "Enjin",
    enabled: false,
  },
  {
    url: "eslint.svg",
    title: "ESlint",
    enabled: true,
  },
  {
    url: "ethereum.svg",
    title: "Ethereum",
    enabled: true,
  },
  {
    url: "fb-pixel.svg",
    title: "FB Pixel",
    enabled: false,
  },
  {
    url: "fortmatic.svg",
    title: "Fortmatic",
    enabled: false,
  },
  {
    url: "gcp.svg",
    title: "Google Cloud Computing",
    enabled: true,
  },
  {
    url: "github.svg",
    title: "GitHub",
    enabled: true,
  },
  {
    url: "gitlab.svg",
    title: "GitLab",
    enabled: true,
  },
  {
    url: "google.svg",
    title: "Google",
    enabled: false,
  },
  {
    url: "graphql.svg",
    title: "GraphQL",
    enabled: false,
  },
  {
    url: "hardhat.svg",
    title: "HardHat",
    enabled: true,
  },
  {
    url: "hyros.svg",
    title: "Hyros",
    enabled: false,
  },
  {
    url: "infura.svg",
    title: "Infura",
    enabled: true,
  },
  {
    url: "intellij-idea.svg",
    title: "IntelliJ IDEA",
    enabled: false,
  },
  {
    url: "intercom.svg",
    title: "Intercom",
    enabled: false,
  },
  {
    url: "jest.svg",
    title: "Jest",
    enabled: true,
  },
  {
    url: "jira.svg",
    title: "Jira",
    enabled: false,
  },
  {
    url: "kubernetes.svg",
    title: "Kubernetes",
    enabled: true,
  },
  {
    url: "ledger.svg",
    title: "ledger",
    enabled: false,
  },
  {
    url: "lerna.svg",
    title: "Lerna",
    enabled: true,
  },
  {
    url: "less.svg",
    title: "LESS",
    enabled: false,
  },
  {
    url: "mailchimp.svg",
    title: "MailChimp",
    enabled: false,
  },
  {
    url: "mailgun.svg",
    title: "MailGun",
    enabled: false,
  },
  {
    url: "mailjet.svg",
    title: "MailJet",
    enabled: true,
  },
  {
    url: "matic.svg",
    title: "Matic",
    enabled: false,
  },
  {
    url: "mend.svg",
    title: "Mend",
    enabled: false,
  },
  {
    url: "mesmo.svg",
    title: "Mesmo",
    enabled: true,
  },
  {
    url: "metamask.svg",
    title: "Meta Mask",
    enabled: true,
  },
  {
    url: "mocha.svg",
    title: "Mocha",
    enabled: true,
  },
  {
    url: "mongodb.svg",
    title: "MongoDB",
    enabled: false,
  },
  {
    url: "moralis.svg",
    title: "Moralis",
    enabled: false,
  },
  {
    url: "mui.svg",
    title: "MUI",
    enabled: true,
  },
  {
    url: "mysql.svg",
    title: "MySQL",
    enabled: false,
  },
  {
    url: "nest-js.svg",
    title: "NestJS",
    enabled: true,
  },
  {
    url: "nft.svg",
    title: "NFT",
    enabled: false,
  },
  {
    url: "node.svg",
    title: "NodeJS",
    enabled: true,
  },
  {
    url: "nx.svg",
    title: "NX",
    enabled: true,
  },
  {
    url: "open-sea.svg",
    title: "Open Sea",
    enabled: true,
  },
  {
    url: "open-zeppelin.svg",
    title: "Open Zeppelin",
    enabled: true,
  },
  {
    url: "papertrail.svg",
    title: "Papertrail",
    enabled: false,
  },
  {
    url: "pinata.svg",
    title: "Pinata",
    enabled: false,
  },
  {
    url: "polygon.svg",
    title: "Polygon",
    enabled: false,
  },
  {
    url: "portis.svg",
    title: "Portis",
    enabled: false,
  },
  {
    url: "postgresql.svg",
    title: "PostgreSQL",
    enabled: true,
  },
  {
    url: "postmark.svg",
    title: "PostMark",
    enabled: false,
  },
  {
    url: "prettier.svg",
    title: "Prettier",
    enabled: true,
  },
  {
    url: "rabbitmq.svg",
    title: "RabbitMQ",
    enabled: true,
  },
  {
    url: "rarible.svg",
    title: "Rarible",
    enabled: false,
  },
  {
    url: "react-js.svg",
    title: "ReactJS",
    enabled: true,
  },
  {
    url: "redis.svg",
    title: "Redis",
    enabled: true,
  },
  {
    url: "renovate.svg",
    title: "Renovate",
    enabled: true,
  },
  {
    url: "sass.svg",
    title: "SASS",
    enabled: false,
  },
  {
    url: "slack.svg",
    title: "Slack",
    enabled: false,
  },
  {
    url: "solarwind.svg",
    title: "SolarWind",
    enabled: false,
  },
  {
    url: "solhint.svg",
    title: "SolHint",
    enabled: true,
  },
  {
    url: "solidity.svg",
    title: "Solidity",
    enabled: true,
  },
  {
    url: "storybook.svg",
    title: "Storybook",
    enabled: true,
  },
  {
    url: "swagger.svg",
    title: "Swagger",
    enabled: true,
  },
  {
    url: "telegram.svg",
    title: "Telegram",
    enabled: true,
  },
  {
    url: "terraform.svg",
    title: "Terraform",
    enabled: true,
  },
  {
    url: "torus.svg",
    title: "Torus",
    enabled: false,
  },
  {
    url: "trello.svg",
    title: "Trello",
    enabled: true,
  },
  {
    url: "trezor.svg",
    title: "Trezor",
    enabled: false,
  },
  {
    url: "typeorm.svg",
    title: "TypeORM",
    enabled: true,
  },
  {
    url: "typescript.svg",
    title: "TypeScript",
    enabled: true,
  },
  {
    url: "vercel.svg",
    title: "Vercel",
    enabled: false,
  },
  {
    url: "wallet-connect.svg",
    title: "Wallet Connect",
    enabled: true,
  },
  {
    url: "web-storm.svg",
    title: "WebStorm",
    enabled: true,
  },
  {
    url: "webflow.svg",
    title: "WebFlow",
    enabled: false,
  },
  {
    url: "websockets.svg",
    title: "Websockets",
    enabled: true,
  },
];

export const TechnicalStack: FC = () => {
  return (
    <Root>
      <Typography variant="h4" sx={{ textAlign: "center", mt: 4 }}>
        <FormattedMessage id="pages.tech.title" />
      </Typography>
      <Typography sx={{ textAlign: "center" }}>
        <FormattedMessage id="pages.tech.description" />
      </Typography>
      <br />
      <br />
      <Typography variant="body2" component="div">
        <Grid container spacing={1.5} justifyContent="center">
          {tech
            .filter(e => e.enabled)
            .map(e => (
              <Grid item key={e.url}>
                <img src={`/files/tech/${e.url}`} alt={e.title} />
              </Grid>
            ))}
        </Grid>
      </Typography>
    </Root>
  );
};
