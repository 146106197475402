import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Link as RouterLink } from "react-router-dom";

import { useMenuItems } from "../../header/menu/use-menu-items";
import type { IMenuItem } from "../../header/menu/use-menu-items";
import { StyledLink, StyledLinksColumnHeader, StyledLinksColumnWrapper } from "../styled";

export const ResourcesColumn: FC = () => {
  const items = useMenuItems();
  const resources: IMenuItem[] = [];

  items.forEach(item => {
    if (item.id === 2) {
      resources.push(item);
    }

    if (item.id === 4 && item.children) {
      resources.push(...item.children);
    }
  });

  return (
    <StyledLinksColumnWrapper>
      <StyledLinksColumnHeader>
        <FormattedMessage id="components.footer.resources.title" />
      </StyledLinksColumnHeader>
      {resources.map(resource => {
        if (resource.external) {
          return (
            <StyledLink key={resource.id} href={resource.link} target="_blank" rel="noreferrer">
              {resource.title}
            </StyledLink>
          );
        }

        return (
          <StyledLink key={resource.id} component={RouterLink} to={resource.link!}>
            {resource.title}
          </StyledLink>
        );
      })}
    </StyledLinksColumnWrapper>
  );
};
