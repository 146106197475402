import { FC, useEffect } from "react";
import { Grid, Typography, Link } from "@mui/material";
import { useWatch, useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import { SwitchInput, CheckboxInput } from "@dzambalaorg/mui-inputs-core";

import { StyledFormGroup } from "./styled";

export const Erc20: FC = () => {
  const form = useFormContext<any>();
  const value = useWatch({ name: "module.erc20" });

  useEffect(() => {
    form.setValue("erc20.contract", value);
  }, [value]);

  return (
    <Grid>
      <SwitchInput name="module.erc20" />
      <StyledFormGroup>
        <CheckboxInput name="erc20.contract" disabled />
        <Typography>
          <FormattedMessage id="pages.calculator.erc20.contract" />
          <Link href="https://dzambalaorg.github.io/admin/hierarchy/ERC20/contract/">more</Link>
        </Typography>
      </StyledFormGroup>
    </Grid>
  );
};
