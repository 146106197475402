import { styled } from "@mui/material/styles";
import { Box, Link, Typography } from "@mui/material";

export const StyledImage = styled(Box)(({ theme }) => ({
  maxWidth: 360,
  width: "100%",
  margin: theme.spacing(0, "auto"),
  display: "block",
  borderRadius: "50%",
})) as typeof Box;

export const StyledLink = styled(Link)(({ theme }) => ({
  margin: theme.spacing(2, "auto"),
  display: "block",
}));

export const StyledIcon = styled(Box)(({ theme }) => ({
  width: 40,
  margin: theme.spacing(0, "auto"),
  display: "block",
})) as typeof Box;

export const StyledText = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  fontSize: theme.typography.pxToRem(24),
  fontWeight: 600,
}));
