import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Theme, useMediaQuery } from "@mui/material";

import { StyledBold } from "../../../components/common/styled";
import { Root, StyledHeader, StyledPartner, StyledPartners, StyledPartnersList } from "./styled";

const partnersList = [
  [
    {
      logo: "/files/landing/images/partners/binance.png",
      link: "https://binance.com",
      alt: "Binance",
    },
    {
      logo: "/files/landing/images/partners/polygon.png",
      link: "https://polygon.technology",
      alt: "Polygon",
    },
    {
      logo: "/files/landing/images/partners/chainlink.png",
      link: "https://chain.link",
      alt: "Chainlink",
    },
    {
      logo: "/files/landing/images/partners/bga.png",
      link: "https://blockchaingamealliance.net",
      alt: "Blockchain Game Alliance",
    },
    {
      logo: "/files/landing/images/partners/satoshi.png",
      link: "https://satoshitwenty.one",
      alt: "Satoshi 21",
    },
  ],
  [
    {
      logo: "/files/landing/images/partners/memory-os.png",
      link: "https://memoryos.com",
      alt: "MemoryOS",
    },
    {
      logo: "/files/landing/images/partners/memoverse.png",
      link: "https://memoverse.io",
      alt: "Memoverse",
    },
    {
      logo: "/files/landing/images/partners/fitburn.png",
      link: "https://fitburn.ai",
      alt: "Fitburn",
    },
    {
      logo: "/files/landing/images/partners/alphax.png",
      link: "https://www.alphax.fund",
      alt: "AlphaX",
    },
    {
      logo: "/files/landing/images/partners/impulse.png",
      link: "https://x.com/impulse_imp",
      alt: "Impulse",
    },
  ],
  [
    {
      logo: "/files/landing/images/partners/gateway-fm.png",
      link: "https://gateway.fm",
      alt: "Gateway",
    },
    {
      logo: "/files/landing/images/partners/gamefi-tech.png",
      link: "https://gamefi.tech",
      alt: "Gamefi Tech",
    },
    {
      logo: "/files/landing/images/partners/whalepass.png",
      link: "https://www.whalepass.gg",
      alt: "Whalepass",
    },
    {
      logo: "/files/landing/images/partners/noders.png",
      link: "https://noders.team",
      alt: "Noders",
    },
    {
      logo: "/files/landing/images/partners/saxar.png",
      link: "https://saxar-metaverse.com",
      alt: "Saxar metaverse",
    },
  ],
  [
    {
      logo: "/files/landing/images/partners/unicsoft.png",
      link: "https://unicsoft.com",
      alt: "Unicsoft",
    },
    {
      logo: "/files/landing/images/partners/red-duck.png",
      link: "https://redduck.io",
      alt: "Redduck",
    },
    {
      logo: "/files/landing/images/partners/whimsy-games.png",
      link: "https://whimsygames.co",
      alt: "Whimsy Games",
    },
    {
      logo: "/files/landing/images/partners/paktolus.png",
      link: "https://paktolus.com",
      alt: "Paktolus",
    },
  ],
];

export const Partners: FC = () => {
  const handleClick = (link: string) => () => {
    window.open(link, "_blank", "noopener noreferrer");
  };

  const isSmallScreen = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));

  return (
    <Root>
      <StyledHeader>
        <FormattedMessage
          id="pages.landing.partners.title"
          values={{
            b: chunks => <StyledBold>{chunks}</StyledBold>,
          }}
        />
      </StyledHeader>
      <StyledPartnersList>
        {partnersList.map((partners, i) =>
          isSmallScreen ? (
            partners.map(partner => (
              <StyledPartner
                component="img"
                key={partner.link}
                alt={partner.alt}
                onClick={handleClick(partner.link)}
                src={partner.logo}
              />
            ))
          ) : (
            <StyledPartners key={i}>
              {partners.map(partner => (
                <StyledPartner
                  component="img"
                  key={partner.link}
                  alt={partner.alt}
                  onClick={handleClick(partner.link)}
                  src={partner.logo}
                />
              ))}
            </StyledPartners>
          ),
        )}
      </StyledPartnersList>
    </Root>
  );
};
