import { FC } from "react";
import { Button, Card, CardActions, CardMedia, Grid, Typography, useTheme } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { PageHeader } from "@dzambalaorg/mui-page-layout";

import { Root } from "../../../components/common/styled";

export const PressKit: FC = () => {
  const theme = useTheme();
  return (
    <Root>
      <Grid>
        <PageHeader message="pages.pressKit.title" />

        <Typography sx={{ mb: 2 }}>
          <FormattedMessage id="pages.pressKit.description" />
        </Typography>

        <Grid container spacing={2} sx={{ mb: 1 }}>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardMedia component="img" image="/files/press-kit/logo.png" title="logo" sx={{ p: 2 }} />
              <CardActions>
                <Button href="/files/press-kit/logo.png" target="_blank">
                  PNG
                </Button>
                <Button href="/files/press-kit/logo.svg" target="_blank">
                  SVG
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardMedia
                component="img"
                image="/files/press-kit/logo_inverse.png"
                title="inverse logo"
                sx={{ p: 2, background: theme.palette.primary.main }}
              />
              <CardActions>
                <Button href="/files/press-kit/logo_inverse.png" target="_blank">
                  PNG
                </Button>
                <Button href="/files/press-kit/logo_inverse.svg" target="_blank">
                  SVG
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardMedia component="img" image="/files/press-kit/logo_text.png" title="logo" sx={{ p: 2 }} />
              <CardActions>
                <Button href="/files/press-kit/logo_text.png" target="_blank">
                  PNG
                </Button>
                <Button href="/files/press-kit/logo_text.svg" target="_blank">
                  SVG
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardMedia
                component="img"
                image="/files/press-kit/logo_text_inverse.png"
                title="inverse logo"
                sx={{ p: 2, background: theme.palette.primary.main }}
              />
              <CardActions>
                <Button href="/files/press-kit/logo_text_inverse.png" target="_blank">
                  PNG
                </Button>
                <Button href="/files/press-kit/logo_text_inverse.svg" target="_blank">
                  SVG
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Root>
  );
};
