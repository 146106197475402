import { FC } from "react";
import { Outlet } from "react-router-dom";

import { Header } from "../header";
import { Footer } from "../footer";
import { Root, StyledContainer } from "./styled";

export const Layout: FC = () => {
  return (
    <>
      <Root>
        <Header />
        <StyledContainer maxWidth="xl">
          <Outlet />
        </StyledContainer>
      </Root>
      <Footer />
    </>
  );
};
