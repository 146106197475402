import { FC } from "react";
import { OpenWith } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";

import { StyledBold } from "../../../components/common/styled";
import { Root, StyledContent, StyledHeader, StyledIcon, StyledItem, StyledItems, StyledMegaHeader } from "./styled";

export const Nfts: FC = () => {
  return (
    <Root>
      <StyledContent>
        <StyledHeader>
          <FormattedMessage id="pages.landing.nfts.title" values={{ b: chunks => <StyledBold>{chunks}</StyledBold> }} />
        </StyledHeader>

        <StyledMegaHeader>
          <FormattedMessage id="pages.landing.nfts.megaTitle" />
        </StyledMegaHeader>

        <StyledItems>
          <StyledItem>
            <StyledIcon component={OpenWith} />
            <FormattedMessage id="pages.landing.nfts.items.item1" />
          </StyledItem>
          <StyledItem>
            <StyledIcon component={OpenWith} />
            <FormattedMessage id="pages.landing.nfts.items.item2" />
          </StyledItem>
          <StyledItem>
            <StyledIcon component={OpenWith} />
            <FormattedMessage id="pages.landing.nfts.items.item3" />
          </StyledItem>
          <StyledItem>
            <StyledIcon component={OpenWith} />
            <FormattedMessage id="pages.landing.nfts.items.item4" />
          </StyledItem>
        </StyledItems>
      </StyledContent>
    </Root>
  );
};
