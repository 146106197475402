import { FC } from "react";
import { FormattedMessage } from "react-intl";

import { ContactType } from "@framework/types";

import { CommonContactForm } from "../../../../components/forms/common-contact";
import { Root } from "../../../../components/common/styled";
import { MakeAnOffer } from "./make-an-offer";
import { StyledDescription, StyledHeader } from "./styled";

export const ContactPartnership: FC = () => {
  return (
    <Root>
      <StyledHeader>
        <FormattedMessage id="pages.partnership.title" />
      </StyledHeader>

      <StyledDescription>
        <FormattedMessage id="pages.partnership.description" />
      </StyledDescription>

      <CommonContactForm contactType={ContactType.PARTNERSHIP}>
        <MakeAnOffer />
      </CommonContactForm>
    </Root>
  );
};
