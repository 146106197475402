import { FC } from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router";

import { PageHeader } from "@dzambalaorg/mui-page-layout";
import { FormWrapper } from "@dzambalaorg/mui-form";
import { SelectInput, TextArea, TextInput } from "@dzambalaorg/mui-inputs-core";
import { useApiCall } from "@dzambalaorg/react-hooks";
import type { IApiContext } from "@dzambalaorg/provider-api";
import type { ISurveyCreateDto } from "@framework/types";
import { ProjectBlockchain, ProjectBudget } from "@framework/types";

import { validationSchema } from "./validation";
import { Root } from "../../../components/common/styled";

export const Survey: FC = () => {
  const navigate = useNavigate();

  const { fn } = useApiCall((api: IApiContext, data: ISurveyCreateDto) => {
    return api.fetchJson({
      url: "/survey",
      method: "POST",
      data,
    });
  });

  const handleSubmit = async (values: ISurveyCreateDto, form: any) => {
    await fn(form, values).then(() => navigate("/"));
  };

  const fixedValues = {
    projectName: "",
    projectGoal: "",
    projectStage: "",
    projectPlatform: "",
    projectBlockchain: [ProjectBlockchain.BINANCE],
    projectBudget: ProjectBudget["< 10"],
  };

  return (
    <Root>
      <PageHeader message="pages.survey.title" />

      <FormWrapper initialValues={fixedValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        <TextInput name="projectName" />
        <TextArea name="projectGoal" />
        <TextArea name="projectRevenue" />
        <TextArea name="projectStage" />
        <TextArea name="projectPlatform" />
        <SelectInput name="projectBlockchain" options={ProjectBlockchain} multiple />
        <SelectInput name="projectBudget" options={ProjectBudget} />
      </FormWrapper>
    </Root>
  );
};
