import { FC } from "react";
import { List, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from "@mui/material";
import { PeopleAlt } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { PageHeader } from "@dzambalaorg/mui-page-layout";

import { Root } from "../../components/common/styled";

export const Dashboard: FC = () => {
  return (
    <Root>
      <PageHeader message="pages.dashboard.title" />

      <List
        component="nav"
        subheader={
          <ListSubheader>
            <FormattedMessage id="pages.dashboard.sections.title" />
          </ListSubheader>
        }
      >
        <ListItemButton component={RouterLink} to="/pricing">
          <ListItemIcon>
            <PeopleAlt />
          </ListItemIcon>
          <ListItemText>
            <FormattedMessage id="pages.dashboard.sections.pricing" />
          </ListItemText>
        </ListItemButton>
        <ListItemButton component={RouterLink} to="/careers">
          <ListItemIcon>
            <PeopleAlt />
          </ListItemIcon>
          <ListItemText>
            <FormattedMessage id="pages.dashboard.sections.careers" />
          </ListItemText>
        </ListItemButton>
        <ListItemButton component={RouterLink} to="/payment">
          <ListItemIcon>
            <PeopleAlt />
          </ListItemIcon>
          <ListItemText>
            <FormattedMessage id="pages.dashboard.sections.payment" />
          </ListItemText>
        </ListItemButton>
      </List>
    </Root>
  );
};
