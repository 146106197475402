import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Box } from "@mui/material";

import { StyledHeader, StyledDescription } from "./styled";

export const MakeAnOffer: FC = () => {
  return (
    <>
      <StyledHeader>
        <FormattedMessage id="pages.partnership.makeAnOffer.title" />
      </StyledHeader>

      <StyledDescription component="ul">
        <Box component="li">
          <FormattedMessage id="pages.partnership.makeAnOffer.p1" />
        </Box>
        <Box component="li">
          <FormattedMessage id="pages.partnership.makeAnOffer.p2" />
        </Box>
        <Box component="li">
          <FormattedMessage id="pages.partnership.makeAnOffer.p3" />
        </Box>
      </StyledDescription>
    </>
  );
};
