import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

export const Root = styled(Box)(({ theme }) => ({
  width: "100%",
  marginTop: theme.spacing(6),
  padding: theme.spacing(4),
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  "& label > span": {
    fontWeight: 600,
  },
}));

export const StyledHeader = styled(Typography)(({ theme }) => ({
  ...theme.typography.h4,
  fontWeight: 500,
  margin: theme.spacing(4, 0),
  width: "100%",
  textAlign: "center",
  [theme.breakpoints.down("md")]: {
    fontSize: 28,
    margin: theme.spacing(4, 0),
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 24,
    margin: theme.spacing(2, 0, 3),
  },
}));

export const StyledDescription = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  fontWeight: 400,
  margin: theme.spacing(1, 0),
  [theme.breakpoints.down("md")]: {
    fontSize: 16,
    fontWeight: 400,
    marginBottom: 0,
  },
})) as typeof Typography;
