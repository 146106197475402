import { FC, useEffect } from "react";
import { Grid, Typography, Link } from "@mui/material";
import { useWatch, useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import { SwitchInput, CheckboxInput } from "@dzambalaorg/mui-inputs-core";

import { StyledFormGroup } from "./styled";

export const Other: FC = () => {
  const form = useFormContext<any>();
  const moduleErc20 = useWatch({ name: "module.erc20" });
  const moduleErc721 = useWatch({ name: "module.erc721" });
  const moduleErc998 = useWatch({ name: "module.erc998" });
  const moduleErc1155 = useWatch({ name: "module.erc1155" });

  useEffect(() => {
    if (!moduleErc721 && !moduleErc998 && !moduleErc1155) {
      form.setValue("other.json", false);
    }
  }, [moduleErc20, moduleErc721, moduleErc998, moduleErc1155]);

  return (
    <Grid>
      <SwitchInput name="modules.other" checked />
      <StyledFormGroup>
        <CheckboxInput name="other.marketplace" disabled />
        <Typography>
          <FormattedMessage id="pages.calculator.other.marketplace" />
        </Typography>
        <CheckboxInput name="other.users" disabled />
        <Typography>
          <FormattedMessage id="pages.calculator.other.users" />
        </Typography>
        <CheckboxInput name="other.emails" />
        <Typography>
          <FormattedMessage id="pages.calculator.other.emails" />
        </Typography>
        <CheckboxInput name="other.game" />
        <Typography>
          <FormattedMessage id="pages.calculator.other.game" />
          <Link href="https://dzambalaorg.github.io/api/category/game-microservice/">more</Link>
        </Typography>
        <CheckboxInput name="other.json" disabled={!(moduleErc721 || moduleErc998 || moduleErc1155)} />
        <Typography>
          <FormattedMessage id="pages.calculator.other.json" />
          <Link href="https://dzambalaorg.github.io/api/category/json-microservice/">more</Link>
        </Typography>
      </StyledFormGroup>
    </Grid>
  );
};
