import { styled } from "@mui/material/styles";
import { Box, Container, ContainerProps, Grid, FormGroup, List, Typography } from "@mui/material";

import type { IScrolledProps } from "../../../../components/common/header/styled";

export const Root = styled(Box)(({ theme }) => ({
  width: "100%",
  marginTop: theme.spacing(6),
  padding: theme.spacing(1, 4, 4),
  borderRadius: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  "& label > span": {
    fontWeight: 600,
  },
  [theme.breakpoints.down("md")]: {
    marginTop: theme.spacing(1),
  },
}));

export const StyledFormGroup = styled(FormGroup)(({ theme }) => ({
  marginLeft: theme.spacing(6),
  "& p": {
    marginLeft: theme.spacing(4),
    "& a": {
      marginLeft: theme.spacing(1),
    },
  },
}));

export const StyledTypographyGroup = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(6),
  "& p": {
    marginLeft: theme.spacing(4),
    "& a": {
      marginLeft: theme.spacing(1),
    },
  },
}));

export const StyledListGroup = styled(List)(({ theme }) => ({
  marginLeft: theme.spacing(6),
  "& p": {
    marginLeft: theme.spacing(4),
    "& a": {
      marginLeft: theme.spacing(1),
    },
  },
}));

export const StyledTotal = styled(Typography)(({ theme }) => ({
  ...theme.typography.h4,
  margin: 0,
  color: theme.palette.primary.main,
  [theme.breakpoints.down("md")]: {
    ...theme.typography.h5,
  },
  lineHeight: "52px", // buttons height
}));

export const StyledContainer = styled(Container, { shouldForwardProp: prop => prop !== "scrolled" })<
  ContainerProps & IScrolledProps
>(({ scrolled, theme }) => ({
  position: "fixed",
  backgroundColor: theme.palette.background.default,
  marginTop: scrolled ? theme.spacing(-5) : theme.spacing(-2),
  marginLeft: theme.spacing(-4),
  marginRight: theme.spacing(1),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(5),
  zIndex: 1000,
  "& h2": {
    marginTop: 0,
    marginBottom: 0,
  },
  [theme.breakpoints.down("lg")]: {
    paddingRight: theme.spacing(9),
    paddingLeft: theme.spacing(1),
  },
  [theme.breakpoints.down("md")]: {
    marginTop: theme.spacing(-2),
    "& h2": {
      fontSize: 26,
      lineHeight: "26px",
    },
    paddingRight: theme.spacing(9),
    paddingBottom: theme.spacing(0),
  },
  [theme.breakpoints.down("sm")]: {
    "& h2": {
      fontSize: 23,
      lineHeight: "23px",
    },
    paddingRight: theme.spacing(4),
  },
}));

export const StyledGridContent = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    paddingTop: theme.spacing(3),
  },
}));

export const StyledForm = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(5),
  [theme.breakpoints.down("md")]: {
    marginTop: theme.spacing(1),
  },
}));
