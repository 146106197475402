import { FC, SyntheticEvent, useState } from "react";
import { SvgIcon } from "@mui/material";
import { FormattedMessage } from "react-intl";

import CloseIcon from "../../../../static/files/landing/new/octagon-close.svg";
import {
  Root,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledHeader,
  StyledContent,
} from "./styled";

export const Faq: FC = () => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Root>
      <StyledHeader>
        <FormattedMessage id="pages.landing.faq.title" />
      </StyledHeader>

      <StyledContent>
        <StyledAccordion
          disableGutters
          elevation={0}
          expanded={expanded === "gemunion"}
          onChange={handleChange("gemunion")}
        >
          <StyledAccordionSummary
            expandIcon={<SvgIcon component={CloseIcon} />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            What is Gemunion?
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            <p>
              Gemunion is a SaaS platform for rapid game prototyping (to showcase a working demo version to investors
              and secure investments), testing theories and identifying market needs, supporting marketing campaigns and
              engage your community while the game is in development.
            </p>
          </StyledAccordionDetails>
        </StyledAccordion>

        <StyledAccordion disableGutters elevation={0} expanded={expanded === "tms"} onChange={handleChange("tms")}>
          <StyledAccordionSummary
            expandIcon={<SvgIcon component={CloseIcon} />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            What is TMS?
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            <p>
              Token Management System is a CMS (WordPress, WIX, Tilda) for blockchain. While in CMS main entity is a
              text and you have all mechanics around it - lists, comments, share buttons. In TMS main entity is a token
              and all mechanics are build around tokens - airdrop, claim, leveling, staking, vesting.
            </p>
          </StyledAccordionDetails>
        </StyledAccordion>

        <StyledAccordion
          disableGutters
          elevation={0}
          expanded={expanded === "mechanics"}
          onChange={handleChange("mechanics")}
        >
          <StyledAccordionSummary
            expandIcon={<SvgIcon component={CloseIcon} />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            What mechanics are supported?
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            <p>
              Our main product supports 20+ mechanics and we constantly adding more. Mechanics are divided into 4
              groups:
            </p>
            <ul>
              <li>marketing - airdrop, claim, collections, vesting, staking, mystery boxes and token wrapping</li>
              <li>gaming - breeding, leveling, craft, dismantle, merge, rent</li>
              <li>gambling - lottery, raffle and prediction</li>
              <li>meta - achievements, payment splitter, promo and referral program</li>
            </ul>
            <p>We are working hard to make them all available on SaaS platform.</p>
          </StyledAccordionDetails>
        </StyledAccordion>
      </StyledContent>
    </Root>
  );
};
