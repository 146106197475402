import { FC } from "react";

import { MenuWrapper, Root, StyledImage } from "./styled";
import { useMenuItems } from "./use-menu-items";
import { MenuItem } from "./menu-item";

export const Menu: FC = () => {
  const menuLinks = useMenuItems();

  return (
    <Root>
      <StyledImage component="img" src="/files/header/icons/bars.png" alt="Gemunion menu" />
      <MenuWrapper>
        {menuLinks.map(item => {
          return <MenuItem key={item.title} menuItem={item} />;
        })}
      </MenuWrapper>
    </Root>
  );
};
