import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  borderColor: theme.palette.primary.main,
  borderWidth: 0,
  borderRadius: theme.spacing(2),
  marginLeft: theme.spacing(2),
  padding: theme.spacing(1.5, 2),
  fontSize: 12,
  fontWeight: 600,
  "&:hover": {
    background: theme.palette.primary.main,
    borderWidth: 0,
    color: theme.palette.primary.contrastText,
  },
  "& .MuiButton-startIcon": {
    marginLeft: theme.spacing(1),
  },
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(1, 2),
  },
})) as typeof Button;
