import { FC } from "react";
import { Typography } from "@mui/material";

import { Root } from "../../../components/common/styled";

export const Careers: FC = () => {
  return (
    <Root>
      <Typography variant="h4">Middle Full-stack Typescript Engineer</Typography>
      <Typography variant="body2" component="div">
        <p>
          We are looking for full-stack typescript developers with at least 3 years of experience to join our company.
          We have a small team of russian speaking developers based in Bali, Indonesia and are looking for someone in
          our timezone who fits our culture.
        </p>
        <p>
          You will be involved in process of development of various projects for our customers using our framework. The
          main direction is GameFi as well as NFT marketplaces, crypto ecommerce and launchpads. If you have yet to
          learn how the blockchain works but have good experience in our stack we will teach you the rest.
        </p>

        <p>Our stack is:</p>
        <ul>
          <li>NestJS framework</li>
          <li>Postgres (typeorm)</li>
          <li>React framework</li>
          <li>Material UI framework (styled-components)</li>
        </ul>

        <p>
          Please see the full list of technologies and other information on our{" "}
          <a href="https://www.gemunion.io/about">website</a>.
        </p>

        <p>
          Our interview process consist of one technical interview and potential technical assignment however be
          prepared to show your GitHub (code) and/or portfolio (past projects).
        </p>
      </Typography>
    </Root>
  );
};
