import { FC, PropsWithChildren } from "react";
import { Grid } from "@mui/material";

import { FormWrapper } from "@dzambalaorg/mui-form";
import { useAppSelector } from "@dzambalaorg/redux";
import { walletSelectors } from "@dzambalaorg/provider-wallet";
import { useApiCall } from "@dzambalaorg/react-hooks";
import { ContactType, EmailType } from "@framework/types";

import { TextInput } from "../../inputs/text";
import { SubmitButton } from "../../buttons";
import { TextAreaInput } from "../../inputs/text-area";
import { Root, StyledForm } from "./styled";
import { validationSchema } from "./schema";

export interface ICommonContactFormProps {
  contactType: ContactType;
  initialValues?: Record<any, any>;
}

export const CommonContactForm: FC<PropsWithChildren<ICommonContactFormProps>> = props => {
  const { children, contactType, initialValues = {} } = props;

  const referrer = useAppSelector<string>(walletSelectors.referrerSelector);

  const { fn: submitEmail } = useApiCall(
    (api, data: any) => {
      return api.fetchJson({
        url: `/emails/${EmailType.CONTACT}`,
        method: "POST",
        data,
      });
    },
    { error: false },
  );

  const onSubmit = async (values: any) => {
    await submitEmail(undefined, values);
  };

  return (
    <Root>
      <Grid container spacing={{ xs: 4, md: 7 }} direction={{ xs: "row", md: "row-reverse" }}>
        <Grid item xs={12} sm={12} md={6}>
          {children}
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <FormWrapper
            initialValues={{
              ...initialValues,
              contactType,
              displayName: "",
              email: "",
              companyName: "",
              jobTitle: "",
              text: "",
              referrer,
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            showButtons={false}
            showPrompt={false}
          >
            <StyledForm>
              <TextInput name="displayName" />
              <TextInput name="email" />
              <TextInput name="companyName" />
              <TextInput name="jobTitle" />
              <TextAreaInput name="text" variant="outlined" />
              <SubmitButton submit="sendRequest" />
            </StyledForm>
          </FormWrapper>
        </Grid>
      </Grid>
    </Root>
  );
};
