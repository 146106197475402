import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledHeader = styled(Typography)(({ theme }) => ({
  ...theme.typography.h4,
  fontWeight: 500,
  margin: theme.spacing(4, 0, 3),
  width: "100%",
  textAlign: "center",
  [theme.breakpoints.down("md")]: {
    fontSize: 28,
    margin: theme.spacing(0, 0, 4),
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 24,
    margin: theme.spacing(0, 0, 3),
  },
}));

export const StyledDescription = styled(Typography)(({ theme }) => ({
  ...theme.typography.h5,
  fontWeight: 400,
  textAlign: "center",
  margin: theme.spacing(0, 0, 5),
  [theme.breakpoints.down("md")]: {
    margin: theme.spacing(0, 0, 5),
    fontSize: 18,
    fontWeight: 400,
  },
}));
