import { styled } from "@mui/material/styles";
import { Box, Grid } from "@mui/material";

export const Root = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

export const StyledNewsletter = styled(Box)({
  display: "flex",
  gap: 16,
  alignItems: "center",
});
