import { FC, useEffect } from "react";
import { Grid, Link, ListItem, Typography } from "@mui/material";
import { useFormContext, useWatch } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import { CheckboxInput, SwitchInput } from "@dzambalaorg/mui-inputs-core";

import { StyledFormGroup, StyledListGroup, StyledTypographyGroup } from "./styled";

export const Erc998: FC = () => {
  const form = useFormContext<any>();
  const moduleNative = useWatch({ name: "module.native" });
  const moduleErc20 = useWatch({ name: "module.erc20" });
  const moduleErc721 = useWatch({ name: "module.erc721" });
  const moduleErc998 = useWatch({ name: "module.erc998" });
  const moduleErc1155 = useWatch({ name: "module.erc1155" });
  const compositionErc20 = useWatch({ name: "erc998.composition.erc20" });
  const compositionErc1155 = useWatch({ name: "erc998.composition.erc1155" });

  useEffect(() => {
    if (!(moduleErc20 || moduleNative) || !moduleErc721) {
      form.setValue("module.erc998", false);
      form.setValue("erc998.contract", false);
      form.setValue("erc998.template", false);
      form.setValue("erc998.token", false);
      form.setValue("erc998.composition.composition", false);
      form.setValue("erc998.composition.erc20", false);
      form.setValue("erc998.composition.erc721", false);
      form.setValue("erc998.composition.erc998", false);
      form.setValue("erc998.composition.erc1155", false);
      return;
    }

    form.setValue("erc998.contract", moduleErc998);
    form.setValue("erc998.template", moduleErc998);
    form.setValue("erc998.token", moduleErc998);
    form.setValue("erc998.composition.composition", moduleErc998);
    form.setValue("erc998.composition.erc20", compositionErc20 && moduleErc20);
    form.setValue("erc998.composition.erc721", moduleErc998);
    form.setValue("erc998.composition.erc998", moduleErc998);
    form.setValue("erc998.composition.erc1155", compositionErc1155 && moduleErc1155);
  }, [moduleNative, moduleErc20, moduleErc721, moduleErc998, moduleErc1155]);

  return (
    <Grid>
      <SwitchInput name="module.erc998" />
      <StyledTypographyGroup>
        <FormattedMessage id="pages.calculator.erc998.requirements" />
      </StyledTypographyGroup>
      <StyledFormGroup>
        <CheckboxInput name="erc998.contract" disabled />
        <Typography component="div">
          <FormattedMessage id="pages.calculator.erc998.contract" />
          <Link href="https://dzambalaorg.github.io/admin/hierarchy/ERC998/contract/">more</Link>
        </Typography>
        <CheckboxInput name="erc998.template" disabled />
        <Typography>
          <FormattedMessage id="pages.calculator.erc998.template" />
          <Link href="https://dzambalaorg.github.io/admin/hierarchy/ERC998/template/">more</Link>
        </Typography>
        <CheckboxInput name="erc998.token" disabled />
        <Typography>
          <FormattedMessage id="pages.calculator.erc998.token" />
          <Link href="https://dzambalaorg.github.io/admin/hierarchy/ERC998/token/">more</Link>
        </Typography>
        <CheckboxInput name="erc998.composition.composition" disabled />
        <Typography>
          <FormattedMessage id="pages.calculator.erc998.composition" />
          <Link href="https://dzambalaorg.github.io/admin/hierarchy/ERC998/composition/">more</Link>
          <StyledListGroup>
            <ListItem disablePadding>
              <CheckboxInput name="erc998.composition.erc20" disabled={!(moduleErc998 && moduleErc20)} />
            </ListItem>
            <ListItem disablePadding>
              <CheckboxInput name="erc998.composition.erc721" disabled />
            </ListItem>
            <ListItem disablePadding>
              <CheckboxInput name="erc998.composition.erc998" disabled />
            </ListItem>
            <ListItem disablePadding>
              <CheckboxInput name="erc998.composition.erc1155" disabled={!(moduleErc998 && moduleErc1155)} />
            </ListItem>
          </StyledListGroup>
        </Typography>
      </StyledFormGroup>
    </Grid>
  );
};
