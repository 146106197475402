import { object, string, mixed, array } from "yup";

import { ProjectBlockchain, ProjectBudget } from "@framework/types";

export const validationSchema = object().shape({
  projectName: string().required("form.validations.valueMissing"),
  projectGoal: string().required("form.validations.valueMissing"),
  projectRevenue: string().required("form.validations.valueMissing"),
  projectStage: string().required("form.validations.valueMissing"),
  projectPlatform: string().required("form.validations.valueMissing"),
  projectBlockchain: array()
    .of(mixed<ProjectBlockchain>().oneOf(Object.values(ProjectBlockchain)))
    .required("form.validations.valueMissing"),
  projectBudget: mixed<ProjectBudget>().oneOf(Object.values(ProjectBudget)).required("form.validations.valueMissing"),
});
