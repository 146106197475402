import { FC } from "react";
import { FormattedMessage } from "react-intl";

import { Root } from "../../../../components/common/styled";
import { StyledDescription, StyledHeader } from "./styled";

export const CalculatorSaas: FC = () => {
  return (
    <Root>
      <StyledHeader>
        <FormattedMessage id="pages.ratePlan.title" />
      </StyledHeader>

      <StyledDescription>
        <FormattedMessage id="pages.ratePlan.description" />
      </StyledDescription>
    </Root>
  );
};
