import { FC } from "react";
import { Typography, Paper } from "@mui/material";

import { Root } from "../../../components/common/styled";

export const EventsList: FC = () => {
  return (
    <Root>
      <Typography variant="h4" sx={{ mb: 2 }}>
        Events
      </Typography>
      <Paper sx={{ p: 2, mb: 2 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          AMA session hosted by CryptorsyFam community
        </Typography>
        <iframe
          width="100%"
          height="640"
          src="https://www.youtube.com/embed/eQfkt_nJ83c"
          title="AMA session hosted by CryptorsyFam community"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </Paper>
      <Paper sx={{ p: 2, mb: 2 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          FitBurn on FIBO
        </Typography>
        <img width="100%" height="auto" src="/files/events/FB138.jpeg" title="FitBurn" />
      </Paper>
      <Paper sx={{ p: 2, mb: 2 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Product Review Meetup at T-HUB
        </Typography>
        <img width="100%" height="auto" src="/files/events/Т-HUB 71.jpg" title="T-HUB" />
      </Paper>
      <Paper sx={{ p: 2, mb: 2 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          INTEGRAL ART - International NFT Conference
        </Typography>
        <iframe
          width="100%"
          height="640"
          src="https://www.youtube.com/embed/FI5vwZiem6g"
          title="INTEGRAL ART - International NFT Conference"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </Paper>
    </Root>
  );
};
