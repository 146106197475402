import { FC } from "react";
import { Link as RouterLink } from "react-router-dom";

import { useProductTypes } from "./hook";
import {
  FeaturesWrapper,
  StyledCard,
  StyledCardContent,
  StyledCardsWrapper,
  StyledCardWrapper,
  StyledScrewNut,
  SubscribeButton,
  TitleTypography,
} from "./styled";

export interface IProductTypeSelectionProps {
  internal?: boolean;
}

export const ProductTypeSelection: FC<IProductTypeSelectionProps> = () => {
  const productTypes = useProductTypes();

  return (
    <StyledCardsWrapper container justifyContent="center">
      {productTypes.map((type, index) => (
        <StyledCardWrapper item xs={12} sm={12} md={6} lg={4} key={index}>
          {type.icons.map(icon => (
            <StyledScrewNut component="img" src={icon.src} sx={icon.sx} key={icon.src} />
          ))}
          <StyledCard>
            <StyledCardContent>
              <TitleTypography variant="h6">{type.title}</TitleTypography>
              <FeaturesWrapper>{type.text}</FeaturesWrapper>
              <SubscribeButton component={RouterLink} to={type.link} size="large" variant="contained">
                {type.linkTitle}
              </SubscribeButton>
            </StyledCardContent>
          </StyledCard>
        </StyledCardWrapper>
      ))}
    </StyledCardsWrapper>
  );
};
