import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Link as RouterLink } from "react-router-dom";

import { useMenuItems } from "../../header/menu/use-menu-items";
import type { IMenuItem } from "../../header/menu/use-menu-items";
import { StyledLink, StyledLinksColumnHeader, StyledLinksColumnWrapper } from "../styled";

export const CompanyColumn: FC = () => {
  const items = useMenuItems();
  const companyItems: IMenuItem[] = [];

  items.forEach(item => {
    if (item.id === 5 && item.children) {
      companyItems.push(...item.children);
    }
  });

  return (
    <StyledLinksColumnWrapper>
      <StyledLinksColumnHeader>
        <FormattedMessage id="components.footer.company.title" />
      </StyledLinksColumnHeader>
      {companyItems.map(item => {
        if (item.external) {
          return (
            <StyledLink key={item.id} href={item.link} target="_blank" rel="noreferrer">
              {item.title}
            </StyledLink>
          );
        }

        return (
          <StyledLink key={item.id} component={RouterLink} to={item.link!}>
            {item.title}
          </StyledLink>
        );
      })}
    </StyledLinksColumnWrapper>
  );
};
