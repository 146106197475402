import { Box, SvgIcon } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledLink = styled(Box)(({ theme }) => ({
  position: "fixed",
  bottom: 40,
  right: 40,
  zIndex: 999,
  [theme.breakpoints.down("sm")]: {
    bottom: 20,
    right: 20,
  },
})) as typeof Box;

export const StyledLogo = styled(SvgIcon)(({ theme }) => ({
  width: 60,
  height: 60,
  color: "#7289da",
  borderRadius: "50%",
  transition: "all 0.2s linear",
  opacity: 0.8,
  "&:hover": {
    opacity: 1,
  },
  [theme.breakpoints.down("sm")]: {
    width: 40,
    height: 40,
  },
})) as unknown as typeof SvgIcon;
