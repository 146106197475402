import { FC } from "react";
import { Navigate } from "react-router";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Paper } from "@mui/material";

import { Error, Message, Protected } from "@dzambalaorg/common-pages";
import { FirebaseLogin } from "@dzambalaorg/firebase-login";
import { MetamaskLoginButton } from "@dzambalaorg/login-button-metamask";
import { ParticleLoginButton } from "@dzambalaorg/login-button-particle";
import { WalletConnectLoginButton } from "@dzambalaorg/login-button-wallet-connect";

import { Layout } from "../components/common/layout";
import { Dashboard } from "./dashboard";
import { Providers } from "./providers";
import { infrastructureRoutes } from "./infrastructure/routes";
import { Landing } from "./landing";
import { portalRoutes } from "./portal/routes";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Providers>
        <Layout />
      </Providers>
    ),
    children: [
      { index: true, element: <Landing /> },
      {
        path: "/dashboard",
        element: <Protected />,
        children: [{ index: true, element: <Dashboard /> }],
      },
      {
        path: "/login",
        element: (
          <Paper>
            <FirebaseLogin
              withEmail={false}
              buttons={[MetamaskLoginButton, WalletConnectLoginButton, ParticleLoginButton]}
            />
          </Paper>
        ),
      },
      ...infrastructureRoutes,
      ...portalRoutes,
      {
        path: "/error/:error",
        element: <Error />,
      },
      {
        path: "/message/:message",
        element: <Message />,
      },
      { path: "*", element: <Navigate to="/message/page-not-found" /> },
    ],
  },
]);

export const App: FC = () => {
  return <RouterProvider router={router} />;
};
