import { FC } from "react";
import { Box } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { StyledDescription } from "../../../../components/forms/common-contact/styled";
import { StyledHeader } from "../../../landing/styled";

export const RequestQuotationText: FC = () => {
  return (
    <>
      <StyledHeader>
        <FormattedMessage id="pages.landing.requestQuotation.title" />
      </StyledHeader>

      <StyledDescription>
        <FormattedMessage id="pages.landing.requestQuotation.description" />
      </StyledDescription>

      <StyledDescription component="ul">
        <Box component="li">
          <FormattedMessage id="pages.landing.requestQuotation.p1" />
        </Box>
        <Box component="li">
          <FormattedMessage id="pages.landing.requestQuotation.p2" />
        </Box>
        <Box component="li">
          <FormattedMessage id="pages.landing.requestQuotation.p3" />
        </Box>
      </StyledDescription>
    </>
  );
};
