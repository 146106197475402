import { FC, useEffect } from "react";
import { Grid, Link, Typography } from "@mui/material";
import { useFormContext, useWatch } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import { CheckboxInput, SwitchInput } from "@dzambalaorg/mui-inputs-core";

import { StyledFormGroup } from "./styled";

export const MechanicsMeta: FC = () => {
  const form = useFormContext<any>();
  const moduleNative = useWatch({ name: "module.native" });
  const moduleErc20 = useWatch({ name: "module.erc20" });
  const moduleErc721 = useWatch({ name: "module.erc721" });
  const moduleErc998 = useWatch({ name: "module.erc998" });
  const moduleErc1155 = useWatch({ name: "module.erc1155" });
  const moduleClaim = useWatch({ name: "mechanics.marketing.claim" });

  useEffect(() => {
    if (!((moduleNative || moduleErc20) && (moduleErc721 || moduleErc998 || moduleErc1155))) {
      form.setValue("mechanics.meta.promo", false);
    }
    if (!((moduleNative || moduleErc20) && moduleClaim)) {
      form.setValue("mechanics.meta.referralProgram", false);
    }
    if (!(moduleNative || moduleErc20)) {
      form.setValue("mechanics.meta.paymentSplitter", false);
    }
    if (!moduleClaim) {
      form.setValue("mechanics.meta.achievements", false);
    }
  }, [moduleNative, moduleErc20, moduleErc721, moduleErc998, moduleErc1155]);

  return (
    <Grid>
      <SwitchInput name="modules.mechanics.meta" checked />
      <StyledFormGroup>
        <CheckboxInput
          name="mechanics.meta.promo"
          disabled={!((moduleNative || moduleErc20) && (moduleErc721 || moduleErc998 || moduleErc1155))}
        />
        <Typography>
          <FormattedMessage id="pages.calculator.mechanics.meta.promo" />
          <Link href="https://dzambalaorg.github.io/admin/mechanics-meta/promo/">more</Link>
        </Typography>
        <CheckboxInput name="mechanics.meta.paymentSplitter" disabled={!(moduleNative || moduleErc20)} />
        <Typography>
          <FormattedMessage id="pages.calculator.mechanics.meta.paymentSplitter" />
          <Link href="https://dzambalaorg.github.io/admin/mechanics-meta/payment-splitter/">more</Link>
        </Typography>
        <CheckboxInput
          name="mechanics.meta.referralProgram"
          disabled={!((moduleNative || moduleErc20) && moduleClaim)}
        />
        <Typography>
          <FormattedMessage id="pages.calculator.mechanics.meta.referralProgram" />
          <Link href="https://dzambalaorg.github.io/market/mechanics-meta/referral-program/">more</Link>
        </Typography>
        <CheckboxInput name="mechanics.meta.achievements" disabled={!moduleClaim} />
        <Typography>
          <FormattedMessage id="pages.calculator.mechanics.meta.achievements" />
          <Link href="https://dzambalaorg.github.io/admin/mechanics-meta/achievements/">more</Link>
        </Typography>
      </StyledFormGroup>
    </Grid>
  );
};
