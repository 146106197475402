import { FC, useEffect } from "react";
import { Grid, Link, Typography } from "@mui/material";
import { useFormContext, useWatch } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import { CheckboxInput, SwitchInput } from "@dzambalaorg/mui-inputs-core";

import { StyledFormGroup } from "./styled";

export const MechanicsGaming: FC = () => {
  const form = useFormContext<any>();
  const moduleNative = useWatch({ name: "module.native" });
  const moduleErc20 = useWatch({ name: "module.erc20" });
  const moduleErc721 = useWatch({ name: "module.erc721" });
  const moduleErc998 = useWatch({ name: "module.erc998" });
  const moduleErc1155 = useWatch({ name: "module.erc1155" });

  useEffect(() => {
    if (!(moduleErc721 || moduleErc998)) {
      form.setValue("mechanics.gaming.grade", false);
    }
    if (!moduleErc1155) {
      form.setValue("mechanics.gaming.craft", false);
    }
    if (!moduleErc1155) {
      form.setValue("mechanics.gaming.dismantle", false);
    }
    if (!(moduleErc721 || moduleErc998)) {
      form.setValue("mechanics.gaming.merge", false);
    }
    if (!(moduleErc721 || moduleErc998)) {
      form.setValue("mechanics.gaming.rent", false);
    }
  }, [moduleNative, moduleErc20, moduleErc721, moduleErc998, moduleErc1155]);

  return (
    <Grid>
      <SwitchInput name="modules.mechanics.gaming" checked />
      <StyledFormGroup>
        <CheckboxInput name="mechanics.gaming.grade" disabled={!(moduleErc721 || moduleErc998)} />
        <Typography>
          <FormattedMessage id="pages.calculator.mechanics.gaming.grade" />
          <Link href="https://dzambalaorg.github.io/admin/mechanics-gaming/grade/">more</Link>
        </Typography>
        <CheckboxInput name="mechanics.gaming.craft" disabled={!moduleErc1155} />
        <Typography>
          <FormattedMessage id="pages.calculator.mechanics.gaming.craft" />
          <Link href="https://dzambalaorg.github.io/admin/mechanics-gaming/recipes/craft/">more</Link>
        </Typography>
        <CheckboxInput name="mechanics.gaming.dismantle" disabled={!moduleErc1155} />
        <Typography>
          <FormattedMessage id="pages.calculator.mechanics.gaming.dismantle" />
          <Link href="https://dzambalaorg.github.io/admin/mechanics-gaming/recipes/dismantle/">more</Link>
        </Typography>
        <CheckboxInput name="mechanics.gaming.merge" disabled={!(moduleErc721 || moduleErc998)} />
        <Typography>
          <FormattedMessage id="pages.calculator.mechanics.gaming.merge" />
          <Link href="https://dzambalaorg.github.io/admin/mechanics-gaming/recipes/merge/">more</Link>
        </Typography>
        <CheckboxInput name="mechanics.gaming.rent" disabled={!(moduleErc721 || moduleErc998)} />
        <Typography>
          <FormattedMessage id="pages.calculator.mechanics.gaming.rent" />
          <Link href="https://dzambalaorg.github.io/admin/mechanics-gaming/rent/">more</Link>
        </Typography>
      </StyledFormGroup>
    </Grid>
  );
};
