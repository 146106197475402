import { FC } from "react";
import { FormattedMessage } from "react-intl";

import MarkerTimeIcon from "../../../../static/files/landing/icons/advantages/marker-time.svg";
import BullseyeIcon from "../../../../static/files/landing/icons/advantages/bullseye-arrow.svg";
import DollarIcon from "../../../../static/files/landing/icons/advantages/badge-dollar.svg";
import ShuttleIcon from "../../../../static/files/landing/icons/advantages/space-shuttle.svg";
import { StyledBold } from "../../../components/common/styled";
import {
  Root,
  StyledItems,
  StyledImage,
  StyledItemTop,
  StyledContent,
  StyledIcon,
  StyledIconWrapper,
  StyledItemBottom,
  StyledTextWrapper,
} from "./styled";

export const Advantages: FC = () => {
  return (
    <Root>
      <StyledImage component="img" src="/files/landing/new/robot-hand.png" />
      <StyledContent>
        <StyledItems>
          <StyledItemTop>
            <StyledIconWrapper>
              <StyledIcon component={MarkerTimeIcon} viewBox="0 0 24 24" />
            </StyledIconWrapper>
            <StyledTextWrapper>
              <FormattedMessage
                id="pages.landing.advantages.items.item1"
                values={{ b: chunks => <StyledBold>{chunks}</StyledBold>, br: <br /> }}
              />
            </StyledTextWrapper>
          </StyledItemTop>
          <StyledItemBottom>
            <StyledIconWrapper>
              <StyledIcon component={BullseyeIcon} viewBox="0 0 24 24" />
            </StyledIconWrapper>
            <StyledTextWrapper>
              <FormattedMessage
                id="pages.landing.advantages.items.item2"
                values={{ b: chunks => <StyledBold>{chunks}</StyledBold>, br: <br /> }}
              />
            </StyledTextWrapper>
          </StyledItemBottom>
          <StyledItemBottom>
            <StyledIconWrapper>
              <StyledIcon component={DollarIcon} viewBox="0 0 24 24" />
            </StyledIconWrapper>
            <StyledTextWrapper>
              <FormattedMessage
                id="pages.landing.advantages.items.item3"
                values={{ b: chunks => <StyledBold>{chunks}</StyledBold>, br: <br /> }}
              />
            </StyledTextWrapper>
          </StyledItemBottom>
          <StyledItemTop>
            <StyledIconWrapper>
              <StyledIcon component={ShuttleIcon} viewBox="0 0 512 512" />
            </StyledIconWrapper>
            <StyledTextWrapper>
              <FormattedMessage
                id="pages.landing.advantages.items.item4"
                values={{ b: chunks => <StyledBold>{chunks}</StyledBold>, br: <br /> }}
              />
            </StyledTextWrapper>
          </StyledItemTop>
        </StyledItems>
      </StyledContent>
    </Root>
  );
};
