import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import { StyledButton } from "./styled";

interface IFormButtonsProps {
  visible?: boolean;
  submit?: string;
}

export const SubmitButton: FC<IFormButtonsProps> = props => {
  const { visible = true, submit = "submit" } = props;

  const {
    formState: { isDirty, isSubmitting, isValid },
  } = useFormContext();

  const disabled = isSubmitting || !(isValid && isDirty);

  if (!visible) {
    return null;
  }

  return (
    <StyledButton variant="contained" size="large" type="submit" color="primary" disabled={disabled} fullWidth>
      <FormattedMessage id={`form.buttons.${submit}`} />
    </StyledButton>
  );
};
