import { FC } from "react";
import { FormattedMessage } from "react-intl";

import { StyledBold } from "../../../components/common/styled";
import { ProductTypeSelection } from "./product-type";
import { Root, StyledCog1, StyledCog2, StyledCog3, StyledHeader } from "./styled";

export const Pricing: FC = () => {
  return (
    <Root>
      <StyledHeader>
        <FormattedMessage
          id="pages.landing.pricing.description"
          values={{ b: chunks => <StyledBold>{chunks}</StyledBold> }}
        />
      </StyledHeader>

      <StyledCog1
        component="img"
        alt="Gemunion pricing cog icon"
        src="/files/landing/new/pricing/outside-screw-nut-middle-left.png"
      />
      <StyledCog2
        component="img"
        alt="Gemunion pricing cog icon"
        src="/files/landing/new/pricing/outside-screw-nut-top-right.png"
      />
      <StyledCog3
        component="img"
        alt="Gemunion pricing cog icon"
        src="/files/landing/new/pricing/outside-screw-nut-bottom-right.png"
      />

      <ProductTypeSelection />
    </Root>
  );
};
