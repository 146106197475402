import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Root = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  marginTop: theme.spacing(5),
  paddingBottom: theme.spacing(2),
  width: "100%",
}));

export const StyledForm = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(5),
  width: "100%",
  [theme.breakpoints.down("md")]: {
    marginTop: theme.spacing(1),
  },
}));

export const StyledDescription = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  fontWeight: 500,
  margin: theme.spacing(1, 0),
  [theme.breakpoints.down("md")]: {
    fontSize: 16,
    fontWeight: 400,
    marginBottom: 0,
    textAlign: "center",
  },
})) as typeof Typography;
